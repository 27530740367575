import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: 'justify' }}>
            Hi Everyone, I am <span className="purple">Magarram Kurbanov </span>
            from <span className="purple"> Moscow, Russia</span>.
            <br />I am currently employed as a software developer at <span style={{ color: 'rgb(248 0 48)' }}>MTS</span>
            .
            <br />
            Check out my{' '}
            <Link class="purple" to="/resume">
              Resume
            </Link>{' '}
            for more info.
            <br />
            <br />
            Apart from coding, here are some other activities that I love to do:
          </p>
          <ul>
            <li className="about-activity">👉🏽 Practicing Religion</li>
            <li className="about-activity">👉🏽 Sports</li>
            <li className="about-activity">👉🏽 Travelling</li>
            <li className="about-activity">👉🏽 Playing Games</li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
